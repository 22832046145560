#root {
  text-align: left;
  margin: .5em;
  margin-bottom: 2em;

  /* HEADER */
  .header {
    // TODO style the header buttons
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    text-align: right;
    width: 100%;
    height: 6vmax;
    z-index: 3;

    /* MENU */
    #menu {
      cursor: pointer;
    }
  }

  /* TITLES */
  h1 {
    text-align: center;
    margin-bottom: 3vw;
  }

  h3 {
    font-weight: inherit;
  }

  /* HORIZONTAL LINE */
  hr {
    margin: 1% 12% 1% 12%;
  }

  /* SEARCH BAR */
  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0 1em 0;

    input {
      height: 1.5em;
      width: 24em;
      margin: 0 .5em 0 .5em;
      box-shadow: 0 0 0 1px;
      border: 1px solid transparent;
      border-radius: 5px;
      font-family: inherit;
    }
  }

  /* ROUTES */
  .routes {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2em;

    a {
      display: inherit;
      justify-content: inherit;
      margin: 1em;
      padding: .3em;
      border: 1px solid black;
      border-radius: 4px;

      svg {
        height: 1.5em;
        width: 1.5em;
        color: #1a1a1a;
      }
    }
  }

  /* DROPDOWN */
  select {
    font-family: inherit;
    font-weight: inherit;
  }

  /* TABLE */
  table {
    margin: .5em 0 1em 0;
    table-layout: auto;
    border-spacing: 0;
    border-collapse: inherit;
    border-radius: 10px;
    border: 1px solid;
  }

  table thead th {
    border-bottom: 1px solid;
  }

  table tbody td:not(:last-child) {
    border-right: 1px solid;
  }

  table tbody tr:not(:last-child)>td {
    border-bottom: 1px solid;
  }

  table thead th:not(:last-child),
  table tbody td:not(:last-child) {
    border-right: 1px solid;
  }

  th,
  td {
    padding: 0 .5em 0 .5em;
    // TODO implement ellipses and click on td to expand the content
  }

  /* CODE */
  code {
    font-size: inherit;
    font-family: inherit;
  }

  .code {
    border: 1px solid black;
    border-radius: 4px;
    padding: 0 1em 0 1em;
  }

  /* FOOTER */
  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2em;
    background-image: linear-gradient(to top, rgb(255, 255, 255), 90%, rgba(255, 255, 255, 0));

    a {
      margin: .5em 1.5% 0 1.5%;
    }

    svg {
      cursor: pointer;
      color: var(--text-color, #1a1a1a);
    }

    svg:hover {
      opacity: 50%;
    }
  }

  /* LABEL */
  label {
    display: none;
  }

  /* ERROR */
  // TODO fix error page layout
  #error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}